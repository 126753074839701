<template>
  <div class="entity-actions">
    <tgl-delete-button
      dispatch="department/deleteDepartment"
      :redirect="{
        name: 'departments',
      }"
      entity="department"
    />
  </div>
</template>

<script>
import TglDeleteButton from "@/components/UI/TglDeleteButton";
export default {
  components: {
    TglDeleteButton,
  },
};
</script>