<template>
  <div class="container-fluid h-650">
    <tgl-card-header>
      <h1>{{ $t("department.departmentDetails.title") }}</h1>
    </tgl-card-header>
    <tgl-card-content>
      <div
        class="
          row
          justify-center
          align-center
          w-100
          department-detail-container
        "
        v-if="department"
      >
        <div class="col-6">
          <div class="row justify-center">
            <span
              ><b>{{ $t("department.departmentDetails.name") }}</b></span
            >
            <span class="ml-2">{{ department.name }}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="row justify-center">
            <span
              ><b>{{ $t("department.departmentDetails.description") }}</b></span
            >
            <span class="ml-2" v-if="department.description">{{
              department.description
            }}</span>
            <span class="ml-2" v-else
              >This department hasn't description yet</span
            >
          </div>
        </div>
      </div>
    </tgl-card-content>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import TglCardHeader from "@/components/Cards/TglCardHeader.vue";
export default {
  name: "department-details",
  components: { TglCardHeader, TglCardContent },
  data() {
    return {
      department: null,
    };
  },
  methods: {
    getDepartment() {
      this.$store
        .dispatch("department/getDepartment", this.$route.params.department)
        .then((data) => {
          this.department = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDepartment();
  },
};
</script>

<style lang="sass">
.department-detail-container
  height: 400px
  display: flex
.h-650
  height: 650px
</style>