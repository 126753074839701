<template>
  <div class="container-fluid">
    <div class="row justify-space-between mr-5">
      <tgl-breadcrumbs :items="breadcrumbs" />
      <department-actions
        v-if="$store.getters.getPermission('departments_remove')"
      ></department-actions>
    </div>
    <div class="container-fluid">
      <v-tabs v-model="tab">
        <v-tab @click="updateRoute('details')">{{
          $t("department.departmentTab.details")
        }}</v-tab>
        <v-tab @click="updateRoute('edit')">{{
          $t("department.departmentTab.edit")
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-2 rounded-md">
        <v-tab-item>
          <department-detail></department-detail>
        </v-tab-item>
        <v-tab-item>
          <department-form></department-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import DepartmentActions from "@/components/Departments/Actions";
import DepartmentForm from "@/components/Departments/DepartmentForm.vue";
import DepartmentDetail from "@/components/Departments/DepartmentDetail.vue";
export default {
  name: "department-view",
  components: {
    TglBreadcrumbs,
    DepartmentActions,
    DepartmentDetail,
    DepartmentForm,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Departamentos",
          disabled: false,
          to: { name: "departments" },
        },
        {
          label: "Departamento",
          disabled: true,
          to: { name: "department" },
        },
      ];
    },
  },
  methods: {
    setTab(tabName) {
      switch (tabName) {
        case "details":
          this.tab = 0;
          break;
        case "edit":
          this.tab = 1;
          break;
        default:
          this.tab = 0;
          break;
      }
    },
    updateRoute(path) {
      this.$router.replace(
        `/department/${this.$route.params.department}/${path}`
      );
    },
  },
  mounted() {
    this.setTab(this.$route.params.tab);
  },
};
</script>